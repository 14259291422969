::-webkit-scrollbar {
  width: 8px;
  height: auto;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(103, 100, 109);
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #000000;
}

html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

#light, #dark {
  min-height: 100vh;  /* Make sure the container height covers the full viewport */
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;            /* Allow this to grow and take available space */
  padding-bottom: 100px;  /* Leave enough space for the fixed footer */
}



