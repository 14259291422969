.contact-section {
  height: 600px;
  margin-left: 20%;
  

  box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
  -webkit-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
  -moz-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
}

.contact-form-design {
  margin-top: 10px;
}


.contact-form-iamge img {
  height: 600px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  margin-top: 50px;
}

.contact-form input {
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}


.contact-form textarea {
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}



.custom-select-tag {
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}



.button-submit p {
  background-color: black;
  padding: 10px;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  width: 50%;
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.container11 {
  position: absolute;
  width: 25%;
  height: 60%;
  perspective: 10px;
  background: linear-gradient(45deg, #000000 25%, transparent 25%),
    linear-gradient(
      -135deg,
      #000000 25%,
      transparent 25%,
      transparent 75%,
      #000000 75%,
      #000000
    );
  background-size: 30px 20px;
}
#linkedin-link {
  text-decoration: none;
  color: black;    
  margin-left: 3px;
  margin-right: 20px;
}