.project-list {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;

  box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
  -webkit-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
  -moz-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
}





.project-list-opned {
  border-left: 5px solid rgb(0, 0, 0);
}

.project-list:hover {
  border-left: 5px solid rgb(0, 0, 0);
  cursor: pointer;
  transition-duration: 1s;
}

.title-and-collapse-option {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.tech-used-in-project {
  margin-top: 20px;
  margin: 5px;
}

.tech-used-in-project p:hover {
  background-color: black;
}

.tech-used-in-project p {
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 90px;
  padding: 8px;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.live-demo-button {
  background-color: rgb(0, 0, 0);
  border-radius: 90px;
  width: fit-content;
  margin: 5px;
  padding: 10px;
}

.live-demo-button:hover {
  background-color: rgb(255, 255, 255);
  transition-duration: 1s;
}

.live-demo-button a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 10px;
}

.live-demo-button a:hover {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 10px;
}

.description {
  margin: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.container{
  margin-left: 20%;
  
}