.testimonial-section {
  margin-top: 70px;
  height: 70vh;
  margin-left: 20%;
}

.content-slider-main {
  margin-top: 70px;
}

.content-slider {
  padding: 10px;
  color: white;
  background-color: #000000;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;
  height: 210px;
  margin: 5px;
  border-radius: 5px;
}



.content-slider:hover {
  background-color: #000000;
  transition-duration: 1s;
}

.content-slider img.center-image {
  height: 80px;
  width: 80px;
  border-radius: 90px;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  margin-top: -30px;
  justify-content: center;
  align-items: center;
  border: 5px solid rgb(0, 0, 0);
}

.slick-prev:before {
  color: #000000 !important;
  font-size: 30px;
}
.slick-next:before {
  color: #000000 !important;
  font-size: 30px;
}
.slick-disable {
  color: rgb(0, 0, 0);
}
