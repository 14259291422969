.mobile-view-navbar {
  position: sticky;
  top: 0;
  z-index: 1;
}

.navbar-header {
  width: 100%;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 5px;
}

.navbar-header p svg {
  margin-left: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.mobile-nav {
  height: auto;
  width: 210px;
  background-color: rgb(0, 0, 0);
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mobile-nav ul li {
  color: white;
  list-style-type: none;
  cursor: pointer;
  text-decoration: none;
  margin-top: 30px;
}

.mobile-nav ul li a {
  text-decoration: none;
  color: white;
}

.nav-item-mobileview .active {
  color: black;
  background-color: #ffffff;
  border-radius: 90px;
  padding: 5px;
  width: 100%;
}

@media (min-width: 1000px) {
  .mobile-view-navbar {
    display: none;
  }
}
