


.about-image img {
  height: 335px;
  width: 300px;
  margin-top: 50px;
  border: 5px solid rgb(0, 0, 0);
  object-fit: cover;
  margin-left: 35px;
}

.about-details {
  margin-top: 50px;
}

.about-title h5 {
  text-align: center;
}

.line {
  display: block;
  width: 70px;
  height: 3px;
  margin: 1px auto;
  background-color: rgb(0, 0, 0);
}

@media (max-width: 1000px) {
  .about-section {
    height: auto;
  }
}
